import { Button } from "@/components/ui/button"
import { api } from "@/utils/api"
import { signInWithEmail, signOut } from "@/utils/supabase"
import { useSession } from "@supabase/auth-helpers-react"
import Head from "next/head"

export default function Home() {
  // api.post.openaiapi.useQuery()
  const session = useSession()

  return (
    <>
      <Head>
        <title>Epic Script</title>
        <meta name="description" content="Epic Script" />
        <link rel="icon" href="/favicon.png" />
      </Head>
      <main>
        <section>
          {session?.user.email ? (
            <Button onClick={signOut}>Sign out</Button>
          ) : (
            <Button onClick={signInWithEmail}>Sign in</Button>
          )}
        </section>
      </main>
    </>
  )
}
